import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
import { GetUsersOnePac_getAllEntities } from 'schemaInterfaces/GetUsersOnePac';

const UsersListToolbar = ({
  searchText,
  setSearchText,
  entities,
}: {
  searchText: string;
  setSearchText: (search: string) => void;
  entities: GetUsersOnePac_getAllEntities;
}) => (
  <Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      <Button color="primary" variant="contained" disabled>
        Ajouter un user
      </Button>
    </Box>
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent>
          <Box sx={{ maxWidth: 500 }}>
            <TextField
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search client"
              variant="outlined"
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  </Box>
);

export default UsersListToolbar;
