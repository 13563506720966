import { useAuth0 } from 'auth/AuthContext';
import { useState } from 'react';
/*
	in this FileUploadRestApi function
	i use the mutation as a Api rest, the graphql method
	is not working because of asura,
	it's just a workaround till we fix the asura issue
*/
const FileUploadRestApi = (query: string, variables: Record<string, any>) => {
  const { accessToken } = useAuth0();
  const [isUploadInProgress, setIsUploadInProgress] = useState<boolean>(false);
  const uploadFile = async (acceptedFiles: File, type: string) => {
    setIsUploadInProgress(true);
    const body = new FormData();
    const file = acceptedFiles;
    console.log('file', type);
    const operation = {
      query,
      variables: { ...variables, file: file, type },
    };
    const map = {
      '0': ['variables.file'],
    };
    body.append('operations', JSON.stringify(operation));
    body.append('map', JSON.stringify(map));
    body.append('0', file);
    console.log('body', body);
    const queryOptions = {
      method: 'POST',
      body,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    try {
      const data = await fetch(
        process.env.REACT_APP_API_ENDPOINT as any,
        queryOptions
      );
      if (data) {
        /*
				i refetch the query to get the products
				i can't use the write query from apollo
				because i use the mutation as a api rest
				*/
        setIsUploadInProgress(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return { isUploadInProgress, setIsUploadInProgress, uploadFile };
};
export default FileUploadRestApi;
