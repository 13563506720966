import React from 'react';

import { Box, Typography, Button, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAuth0 } from 'auth/AuthContext';
import { useApolloClient } from '@apollo/client';

interface SplashProps {
  title?: string;
  body?: string;
  homeButton?: boolean;
}

const Splash = ({ title, body, homeButton }: SplashProps): JSX.Element => {
  const classes = makeStyles((theme) => ({
    background: {
      height: '100vh',
      backgroundColor: '#326cf0',
    },
  }))();

  const client = useApolloClient();
  const { logout } = useAuth0();

  const handleLogout = async () => {
    logout();
    await client.resetStore();
  };

  const logoUrl = `/images/logo.png`;

  return (
    <Box
      className={classes.background}
      display="flex"
      flexDirection="column"
      textAlign="center"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
        flexGrow={1}
        px={3}
      >
        <Box pb={3}>
          <img src={logoUrl} width={210} alt="BigOne Logo" />
        </Box>
        <Typography paragraph variant="h4" color="textPrimary">
          {title}
        </Typography>
        <Typography gutterBottom variant="subtitle2" color="textPrimary">
          {body}
        </Typography>
        {homeButton && (
          <Container style={{ display: 'flex', justifyContent: 'center' }}>
            <Box p={4}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                href="/"
              >
                Home
              </Button>
            </Box>
            <Box p={4}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleLogout}
              >
                Disconnect
              </Button>
            </Box>
          </Container>
        )}
      </Box>
    </Box>
  );
};

export default Splash;
