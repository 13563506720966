import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import TableContainer from '@mui/material/TableContainer';
import { GetClientsOneSupply_getSupplyClients } from 'schemaInterfaces/GetClientsOneSupply';

const ConfirmationDialog = ({
  open,
  handleClose,
  client,
  handleAddClientToOnePac,
}: {
  open: boolean;
  handleClose: () => void;
  client: GetClientsOneSupply_getSupplyClients | undefined;
  handleAddClientToOnePac: (
    client: GetClientsOneSupply_getSupplyClients
  ) => void;
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Ajouter un client sur OnePac
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Êtes-vous sur de vouloir ajouter {client?.name} sur OnePac ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button style={{ textTransform: 'none' }} onClick={handleClose}>
          Annuler
        </Button>
        <Button
          onClick={() => {
            client && handleAddClientToOnePac(client);
            handleClose();
          }}
          style={{ textTransform: 'none' }}
          autoFocus
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ClientsListResults = ({
  clients,
  searchText,
  handleAddClientToOnePac,
  ...rest
}: {
  clients: GetClientsOneSupply_getSupplyClients[];
  searchText: string;
  handleAddClientToOnePac: (
    client: GetClientsOneSupply_getSupplyClients
  ) => void;
}) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState<
    GetClientsOneSupply_getSupplyClients
  >();

  return (
    <Card {...rest}>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients.map((client) => (
                <TableRow
                  key={client.id}
                  sx={{ '& > *': { borderBottom: 'unset' } }}
                >
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      {client.id}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <Typography color="textPrimary" variant="body1">
                        {client.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                      }}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setSelectedClient(client);
                          setIsConfirmationOpen(true);
                        }}
                        style={{ textTransform: 'none' }}
                      >
                        Importer dans onepac
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* <TablePagination
        component="div"
        count={clients.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      /> */}
      <ConfirmationDialog
        open={isConfirmationOpen}
        handleClose={() => setIsConfirmationOpen(false)}
        client={selectedClient}
        handleAddClientToOnePac={handleAddClientToOnePac}
      />
    </Card>
  );
};

ClientsListResults.propTypes = {
  clients: PropTypes.array.isRequired,
};

export default ClientsListResults;
