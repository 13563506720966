import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddOnePacUser from '../ClientsOnePac/modals/AddOnePacUser';
import { GetUsersOnePac_getPublicUserProfiles } from 'schemaInterfaces/GetUsersOnePac';

const UsersListResults = ({
  users,
  searchText,
  handleUpdateUser,
  entities,
  clients,
  ...rest
}: {
  users: any[];
  searchText: string;
  handleUpdateUser: (user: any, id: String) => void;
  entities: any[];
  clients: any[];
}) => {
  const [open, setOpen] = useState('');
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<
    GetUsersOnePac_getPublicUserProfiles
  >();

  return (
    <Card {...rest}>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>ID</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Given Name</TableCell>
                <TableCell>Family Name</TableCell>
                <TableCell>Client</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <React.Fragment key={user.id}>
                  <TableRow
                    key={user.id}
                    sx={{ '& > *': { borderBottom: 'unset' } }}
                  >
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          if (user.id === open) setOpen('');
                          else setOpen(user.id);
                        }}
                      >
                        {open === user.id ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        {user.id}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {user.email}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {user.givenName}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {user.familyName}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {clients.find((client) => client.id === user.clientId)
                            ?.name ?? 'N/C'}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setSelectedUser(user);
                            setIsAddUserOpen(true);
                          }}
                          style={{ textTransform: 'none' }}
                        >
                          Modifier ce User
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        timeout="auto"
                        unmountOnExit
                        in={open === user.id}
                      >
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Entities
                          </Typography>
                          <Grid container>
                            {user.entities.map((entity: any) => (
                              <React.Fragment key={entity.id}>
                                <Grid item container direction="column" xs={6}>
                                  <p
                                    style={{
                                      marginRight: 20,
                                      fontWeight: 700,
                                      fontSize: 16,
                                    }}
                                  >
                                    ID
                                  </p>
                                  <p>{entity.id} </p>
                                </Grid>
                                <Grid item container direction="column" xs={6}>
                                  <p
                                    style={{
                                      marginRight: 20,
                                      fontWeight: 700,
                                      fontSize: 16,
                                    }}
                                  >
                                    Name
                                  </p>
                                  <p>{entity.name} </p>
                                </Grid>
                              </React.Fragment>
                            ))}
                          </Grid>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {isAddUserOpen && (
        <AddOnePacUser
          open={isAddUserOpen}
          setIsOpen={setIsAddUserOpen}
          user={{
            ...selectedUser,
            role: selectedUser?.role?.id,
            entities: selectedUser?.entities?.map((entity) => entity.id),
          }}
          clientId={selectedUser?.clientId}
          entities={entities}
          handleOnSave={handleUpdateUser}
        />
      )}
    </Card>
  );
};

UsersListResults.propTypes = {
  users: PropTypes.array.isRequired,
};

export default UsersListResults;
