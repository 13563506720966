const Logo = (props: any) => (
  <img
    alt="Logo"
    src="/static/logoBigOne.png"
    style={{ width: 50 }}
    {...props}
  />
);

export default Logo;
