import { Navigate } from 'react-router-dom';
import DashboardLayout from './Layout/DashboardLayout';
import ClientsOneSupply from './pages/ClientsOneSupply/ClientsOneSupply';
import UsersOnePac from './pages/UsersOnePac/UsersOnePac';
import ClientsOnePac from './pages/ClientsOnePac/ClientsOnePac';
import AccessDenied from 'pages/AccessDenied/AccessDenied';

const routes = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      { path: '/clients-onesupply', element: <ClientsOneSupply /> },
      { path: '/clients-onepac', element: <ClientsOnePac /> },
      { path: '/users-onepac', element: <UsersOnePac /> },
      { path: '/', element: <Navigate to="/clients-onesupply" /> },
    ],
  },
  {
    path: '/access-denied',
    element: <AccessDenied />,
  },
];

export default routes;
