import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import { ReactElement, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import Select from 'react-select';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { CreateUser_createUser } from 'schemaInterfaces/CreateUser';
import { GetClientRole_getClientRoles } from 'schemaInterfaces/GetClientRole';
import { GetClientsOnePac_getAllEntities } from 'schemaInterfaces/GetClientsOnePac';

const GET_CLIENT_ROLES = gql`
  query GetClientRole($clientId: String!) {
    getClientRoles(clientId: $clientId) {
      id
      name
      description
    }
  }
`;

const AddOnePacUser = ({
  open,
  setIsOpen,
  client,
  entities,
  user,
  clientId,
  handleOnSave,
}: {
  open: boolean;
  setIsOpen: (isOpen: boolean) => void;
  client?: any;
  entities: any;
  user?: any;
  clientId?: string;
  handleOnSave: (user: any, id: String) => void;
}): ReactElement => {
  const { data, error, loading, refetch } = useQuery(GET_CLIENT_ROLES, {
    fetchPolicy: 'no-cache',
    context: { clientName: 'onepac' },
    variables: {
      clientId: '',
    },
  });

  const validationSchema = yup.object({});

  const formik = useFormik({
    initialValues: user
      ? { ...user }
      : {
          email: '',
          givenName: '',
          familyName: '',
          password: '',
          entities: [],
          role: '',
          type: '',
          isAdmin: false,
        },
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      handleOnSaveUser(values);
    },
  });

  function handleClose() {
    setIsOpen(false);
  }

  function handleOnSaveUser(values: CreateUser_createUser) {
    handleOnSave(
      { ...values, clientId: client?.id ?? user.clientId },
      client?.id ?? user.id
    );
    handleClose();
  }

  useEffect(() => {
    if (client || clientId) {
      refetch({ clientId: clientId ?? client?.id });
    }
  }, [client, clientId, refetch]);

  function getUserRole() {
    const role = data?.getClientRoles.find(
      (role: GetClientRole_getClientRoles) => role.id === formik.values.role
    );

    if (role) {
      return {
        ...role,
        value: role?.id,
        label: role?.name,
      };
    } else return undefined;
  }

  function getUserEntities() {
    const formattedEntities =
      formik.values?.entities?.map((entity: any) => {
        const matchedEntity = entities.find(
          (en: GetClientsOnePac_getAllEntities) => en.id === entity
        );

        return {
          ...matchedEntity,
          label: matchedEntity?.name ?? '',
          value: matchedEntity?.id ?? '',
        };
      }) ?? [];

    return formattedEntities;
  }

  if (loading) return <CircularProgress />;
  if (error) return <div>There is an error</div>;

  return (
    <div>
      <form
        onSubmit={() => console.log('here')}
        style={{ marginTop: 20, marginBottom: 50, paddingBottom: 100 }}
      >
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle style={{ fontSize: 18 }}>
            {user?.id ? "Modification d'un user" : 'Ajout un user'}
          </DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              {client
                ? ` Ajouter un user au client ${client.name}.`
                : `Ajouter un admin elpev.`}
              <br />
              Ce user sera ajouté sur OnePac et sur Auth0.
            </DialogContentText>
            <Grid container spacing={2}>
              {client && (
                <Grid item xs={12}>
                  <TextField
                    size="small"
                    margin="dense"
                    id="client"
                    label="Client"
                    fullWidth
                    variant="outlined"
                    value={client?.name ?? 'Aucun client'}
                    disabled
                  />
                </Grid>
              )}
              <Grid item xs={7}>
                <TextField
                  size="small"
                  margin="dense"
                  id="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  variant="outlined"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </Grid>
              {!user && (
                <Grid item xs={5}>
                  <TextField
                    size="small"
                    margin="dense"
                    id="password"
                    label="Password"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <TextField
                  size="small"
                  margin="dense"
                  id="givenName"
                  label="Given Name"
                  fullWidth
                  variant="outlined"
                  value={formik.values.givenName}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  size="small"
                  margin="dense"
                  id="familyName"
                  label="Family Name"
                  fullWidth
                  variant="outlined"
                  value={formik.values.familyName}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item container direction="column" xs={12}>
                <label style={{ paddingBottom: 10 }}>Type</label>
                <Select
                  name="type"
                  id="type"
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  options={[
                    { value: 'Global', label: 'Global' },
                    { value: 'Local', label: 'Local' },
                  ]}
                  placeholder="Sélectionner le type"
                  value={{
                    label: formik.values.type,
                    value: formik.values.type,
                  }}
                  onChange={(selectedValue) =>
                    formik.setFieldValue('type', selectedValue?.value)
                  }
                />
              </Grid>
              <Grid item container direction="column" xs={12}>
                <label style={{ paddingBottom: 10 }}>Role</label>
                <Select
                  name="role"
                  id="role"
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    menu: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  options={data?.getClientRoles.map(
                    (role: GetClientRole_getClientRoles) => ({
                      ...role,
                      label: role.name,
                      value: role.id,
                    })
                  )}
                  placeholder="Sélectionner le rôle de ce user "
                  value={getUserRole()}
                  onChange={(selectedValue) =>
                    formik.setFieldValue('role', selectedValue.value)
                  }
                />
              </Grid>
              <Grid item container direction="column" xs={12}>
                <label style={{ paddingBottom: 10 }}>Super Admin</label>
                <Switch
                  checked={formik.values.isAdmin}
                  onChange={(e) =>
                    formik.setFieldValue('isAdmin', e.target.checked)
                  }
                />
              </Grid>
              <Grid item container direction="column" xs={12}>
                <label style={{ paddingBottom: 10 }}>Entities</label>
                <Select
                  isMulti
                  name="entities"
                  id="entities"
                  styles={{
                    container: (base) => ({
                      ...base,
                      marginBottom: 50,
                    }),
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      marginBottom: 50,
                    }),
                    menu: (base) => ({
                      ...base,
                      marginBottom: 50,
                      zIndex: 9999,
                    }),
                  }}
                  options={entities.map(
                    (entity: GetClientsOnePac_getAllEntities) => ({
                      value: entity.id,
                      label: entity.name,
                    })
                  )}
                  placeholder="Select the entites for this user"
                  value={getUserEntities()}
                  onChange={(selectedValue) =>
                    formik.setFieldValue(
                      'entities',
                      selectedValue.map((value) => value.value)
                    )
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ paddingTop: 20 }}>
            <Button onClick={handleClose}>Annuler</Button>
            <Button
              type="submit"
              variant="contained"
              onClick={formik.handleSubmit as any}
            >
              Enregistrer
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default AddOnePacUser;
