import React from 'react';
import Splash from 'components/Splash/Splash';

const NotFound = (): JSX.Element => {
  return (
    <Splash
      homeButton
      title="Access Denied"
      body="You don't have access to this page, you need to be super admin."
    />
  );
};

export default NotFound;
