import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Alert,
  AlertColor,
  Box,
  CircularProgress,
  Container,
  Snackbar,
} from '@mui/material';
import ClientsListResults from '../../components/ClientsOnePac/ClientsListResults';
import ClientsListToolbar from '../../components/ClientsOnePac/ClientsListToolbar';
import { gql, useMutation, useQuery } from '@apollo/client';
import { GetClientsOnePac } from 'schemaInterfaces/GetClientsOnePac';

const GET_USERS = gql`
  query GetClientsOnePac {
    clients {
      id
      name
      offerIds
      config {
        clientAssets {
          logo
          banner
          sms
          digital
        }
      }
    }
    getAllEntities {
      id
      name
      clientId
    }
  }
`;

const ADD_USER_ONEPAC = gql`
  mutation CreateUser($user: CreateUserInput!, $clientId: String!) {
    createUser(user: $user, clientId: $clientId) {
      id
      clientId
      email
      givenName
      familyName
      type
      role {
        name
      }
      team {
        name
      }
    }
  }
`;

const ClientsOnePac = () => {
  const [searchText, setSearchText] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState({
    message: 'Le user a bien été ajouté sur OnePac !',
    severity: 'success',
  });
  const { data, error, loading, refetch } = useQuery<GetClientsOnePac>(GET_USERS, {
    context: { clientName: 'onepac' },
    fetchPolicy: 'no-cache',
  });
  const [addUserOnePac, { data: dataMutation }] = useMutation(ADD_USER_ONEPAC, {
    context: { clientName: 'onepac' },
    errorPolicy: 'all',
  });

  function handleAddUser(user: any, clientId: String) {
    console.log('add user here', user, clientId);
    addUserOnePac({
      variables: {
        user: user,
        clientId: clientId,
      },
    });
    // uploadFile()
  }

  useEffect(() => {
    if (dataMutation) {
      setSnackbarContent({
        message: 'Le user a bien été ajouté sur OnePac !',
        severity: 'success',
      });
      setIsSnackbarOpen(true);
    }
  }, [dataMutation]);

  if (loading) return <CircularProgress />;
  if (error) return <div>There is an error</div>;
  return (
    <div>
      <Helmet>
        <title>OneManager - OP Clients</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
          overflow: 'hidden',
        }}
      >
        <Container maxWidth={false}>
          <ClientsListToolbar
            searchText={searchText}
            setSearchText={setSearchText}
            entities={data?.getAllEntities ?? []}
          />
          <Box sx={{ pt: 3 }}>
            <ClientsListResults
              clients={
                searchText?.length === 0
                  ? data?.clients
                  : data?.clients.filter((client: any) => {
                    return client.name
                      .toLowerCase()
                      .includes(searchText.toLowerCase());
                  })
              }
              searchText={searchText}
              handleAddUser={handleAddUser}
              entities={data?.getAllEntities ?? []}
              refetch={refetch}
            />
          </Box>
        </Container>
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity={snackbarContent.severity as AlertColor}
          variant="filled"
        >
          {snackbarContent.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ClientsOnePac;
