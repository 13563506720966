import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
} from '@mui/material';
import { useState } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { GetClientsOnePac_getAllEntities } from 'schemaInterfaces/GetClientsOnePac';
import AddOnePacUser from './modals/AddOnePacUser';

const ClientsListToolbar = ({
  searchText,
  setSearchText,
  entities,
}: {
  searchText: string;
  setSearchText: (text: string) => void;
  entities: GetClientsOnePac_getAllEntities[];
}) => {
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => setIsAddUserOpen(true)}
          disabled
        >
          Ajouter un Admin Elpev
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search client"
                variant="outlined"
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
      {isAddUserOpen && (
        <AddOnePacUser
          open={isAddUserOpen}
          setIsOpen={setIsAddUserOpen}
          client={null}
          entities={entities}
          handleOnSave={() => console.log('Globally add a user')}
        />
      )}
    </Box>
  );
};

export default ClientsListToolbar;
