import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Snackbar,
} from '@mui/material';
import ClientsListResults from '../../components/ClientsOneSupply/ClientsListResults';
import ClientsListToolbar from '../../components/ClientsOneSupply/ClientsListToolbar';
import { gql, useMutation, useQuery } from '@apollo/client';
import { GetClientsOneSupply_getSupplyClients } from 'schemaInterfaces/GetClientsOneSupply';

const GET_CLIENTS = gql`
  query GetClientsOneSupply {
    getSupplyClients {
      name
      id
    }
  }
`;

const ADD_CLIENT_TO_ONEPAC = gql`
  mutation AddClientFromSupply($supplyClientId: String!) {
    importClientFromSupply(supplyClientId: $supplyClientId) {
      id
      supplyClientId
    }
  }
`;

const ClientsOneSupply = () => {
  const [searchText, setSearchText] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const { data, error, loading } = useQuery(GET_CLIENTS, {
    context: { clientName: 'onepac' },
    fetchPolicy: 'no-cache',
  });
  const [addClientToOnePac, { data: dataMutation }] = useMutation(
    ADD_CLIENT_TO_ONEPAC,
    {
      context: { clientName: 'onepac' },
    }
  );

  function handleAddClientToOnePac(
    client: GetClientsOneSupply_getSupplyClients
  ) {
    addClientToOnePac({ variables: { supplyClientId: client.id } });
  }

  useEffect(() => {
    if (dataMutation) {
      setIsSnackbarOpen(true);
    }
  }, [dataMutation]);

  if (loading) return <CircularProgress />;
  if (error) return <div>There is an error</div>;
  return (
    <div>
      <Helmet>
        <title>OneManager - OS Clients</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
          overflow: 'hidden',
        }}
      >
        <Container maxWidth={false}>
          <ClientsListToolbar
            searchText={searchText}
            setSearchText={setSearchText}
          />
          <Box sx={{ pt: 3 }}>
            <ClientsListResults
              clients={
                searchText.length === 0
                  ? data?.getSupplyClients
                  : data?.getSupplyClients.filter((client: any) => {
                      return client.name
                        .toLowerCase()
                        .includes(searchText.toLowerCase());
                    })
              }
              searchText={searchText}
              handleAddClientToOnePac={handleAddClientToOnePac}
            />
          </Box>
        </Container>
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity="success"
          variant="filled"
        >
          Le client a bien été ajouté sur OnePac !
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ClientsOneSupply;
