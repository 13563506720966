import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import { ReactElement, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { styled } from '@mui/system';
import FileUploadRestApi from '../../../utils/uploadFile';

const Input = styled('input')({
  // display: "none",
});

const UPLOAD_CLIENT_ASSET = `
  mutation UploadClientAsset(
    $clientId: String!
    $type: String!
    $file: Upload!
  ) {
    uploadClientAsset(clientId: $clientId, type: $type, file: $file) {
      id
    }
  }
`;

const ConfigureOnePacClient = ({
  open,
  setIsOpen,
  client,
}: {
  open: boolean;
  setIsOpen: (isOpen: boolean) => void;
  client: any;
}): ReactElement => {
  const [logo, setLogo] = useState<File>();
  const [banner, setBanner] = useState<File>();
  const [sms, setSms] = useState<File>();
  const [digital, setDigital] = useState<File>();

  const variables = { file: null, clientId: client?.id, type: '' };

  const { uploadFile } = FileUploadRestApi(UPLOAD_CLIENT_ASSET, variables);

  function handleClose() {
    setIsOpen(false);
  }

  async function handleOnSaveConfiguration(values: any) {
    // let formData = new FormData();

    // formData.append("file", logo);

    if (logo) {
      await uploadFile(logo, 'logo');
    }
    if (banner) {
      await uploadFile(banner, 'banner');
    }
    if (sms) {
      await uploadFile(sms, 'sms');
    }
    if (digital) {
      await uploadFile(digital, 'digital');
    }
    // await uploadClientAsset({
    //   variables: { clientId: client.id, type: "logo", file: formData },
    // });
    console.log('e', values);
  }

  const validationSchema = yup.object({});

  const formik = useFormik({
    initialValues: {},
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      handleOnSaveConfiguration(values);
    },
  });

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle style={{ fontSize: 18 }}>
          Configuration of a Client
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            You can configure {client?.name}.<br />
          </DialogContentText>
          <form
            onSubmit={formik.handleSubmit}
            style={{ marginTop: 20, marginBottom: 50, paddingBottom: 100 }}
          >
            <Grid container spacing={2}>
              <Grid item container xs={12} direction="column">
                <label style={{ marginBottom: 10 }}>Logo au format png</label>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={(e: any) => {
                      if (e) {
                        let file = e?.target?.files![0];

                        setLogo(file);
                      }
                    }}
                  />
                  {/* <Button
                    variant="contained"
                    component="span"
                    startIcon={<Upload />}
                  >
                    Upload
                  </Button> */}
                </label>
              </Grid>
              <Grid item container xs={12} direction="column">
                <label style={{ marginBottom: 10 }}>
                  Visuel du header au format 1920x280 px
                </label>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => {
                      if (e) {
                        let file = e?.target?.files![0];

                        setBanner(file);
                      }
                    }}
                  />
                  {/* <Button
                    variant="contained"
                    component="span"
                    startIcon={<Upload />}
                  >
                    Upload
                  </Button> */}
                </label>
              </Grid>
              <Grid item container xs={6} direction="column">
                <label style={{ marginBottom: 10 }}>
                  Visuel du module Email/SMS
                </label>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => {
                      if (e) {
                        let file = e?.target?.files![0];

                        setSms(file);
                      }
                    }}
                  />
                  {/* <Button
                    variant="contained"
                    component="span"
                    startIcon={<Upload />}
                  >
                    Upload
                  </Button> */}
                </label>
              </Grid>
              <Grid item container xs={6} direction="column">
                <label style={{ marginBottom: 10 }}>
                  Visuel du module Digital
                </label>
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={(e) => {
                      if (e) {
                        let file = e?.target?.files![0];

                        setDigital(file);
                      }
                    }}
                  />
                  {/* <Button
                    variant="contained"
                    component="span"
                    startIcon={<Upload />}
                  >
                    Upload
                  </Button> */}
                </label>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleOnSaveConfiguration} variant="contained">
            Sauvegarder la config
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfigureOnePacClient;
