import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import { ReactElement } from 'react';
import { useFormik } from 'formik';
import { ApolloQueryResult, gql, OperationVariables, useMutation, useQuery } from '@apollo/client';
import { GetAllOffers } from 'schemaInterfaces/GetAllOffers';
import { UpdateClientOffers, UpdateClientOffersVariables } from 'schemaInterfaces/UpdateClientOffers';
import { GetClientsOnePac, GetClientsOnePac_clients } from 'schemaInterfaces/GetClientsOnePac';

export const GET_OFFERS = gql`
  query GetAllOffers {
    getAllOffers {
      id
      name
      key
      description
    }
  }
`;

const UPDATE_CLIENT_OFFERS = gql`
    mutation UpdateClientOffers(
      $clientId: String!
      $offerIds: [String!]!
    ) {
      updateClientOffers(clientId: $clientId, offerIds: $offerIds) {
        id,
        name,
        offerIds
      }
    }
  `;

const ConfigureClientOffers = ({
    open,
    setIsOpen,
    client,
    refetch
}: {
    open: boolean;
    setIsOpen: (isOpen: boolean) => void;
    client: GetClientsOnePac_clients | undefined;
    refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<GetClientsOnePac>>;
}): ReactElement => {
    const { data } = useQuery<GetAllOffers>(GET_OFFERS, {
        context: { clientName: 'onepac' },
        fetchPolicy: 'no-cache',
    });

    function handleClose() {
        setIsOpen(false);
    }

    const [updateOffers] = useMutation<UpdateClientOffers, UpdateClientOffersVariables>(UPDATE_CLIENT_OFFERS, {
        context: { clientName: 'onepac' },
        errorPolicy: 'all',
        onCompleted() {
            handleClose()
        }
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { offers: client?.offerIds },
        onSubmit: async (values) => {
            await updateOffers({
                variables: {
                    clientId: client?.id ?? "",
                    offerIds: values.offers ?? []
                },
            });
            refetch()
        },
    });

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, name } = event.target;
        if (checked) {
            formik.setFieldValue('offers', [...formik.values.offers ?? [], name]);
        } else {
            formik.setFieldValue(
                'offers',
                formik.values.offers?.filter((offer: string) => offer !== name)
            );
        }
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle style={{ fontSize: 18 }}>
                    Configuration les offres
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        You can configure {client?.name}.<br />
                    </DialogContentText>
                    <form
                        onSubmit={formik.handleSubmit}
                        style={{ marginTop: 20, marginBottom: 50, paddingBottom: 100 }}
                    >
                        {data?.getAllOffers?.map((offer, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        onChange={(event) => handleChange(event)}
                                        name={offer.id}
                                        checked={formik.values.offers?.includes(offer.id)}
                                    />
                                }
                                label={offer.name}
                                value={offer.id}
                            />
                        ))}
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Annuler</Button>
                    <Button type="submit"
                        variant="contained"
                        onClick={formik.handleSubmit as any}>
                        Sauvegarder la config
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ConfigureClientOffers;
