import './App.css';

import { Alert, AlertColor, Snackbar } from '@mui/material';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { GlobalStyles, StyledEngineProvider } from '@mui/styled-engine';
import { useEffect, useState } from 'react';

import { ThemeProvider } from '@mui/system';
import { onError } from '@apollo/link-error';
import routes from './routes';
import theme from './theme';
import { typeDefs } from './typeDefs';
import { useAuth0 } from './auth/AuthContext';
import { useRoutes } from 'react-router-dom';

function App() {
  const content = useRoutes(routes);
  const { accessToken } = useAuth0();
  const [client, setClient] = useState<any>(undefined);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState({
    message: 'Le user a bien été ajouté sur OnePac !',
    severity: 'success',
  });

  useEffect(() => {
    // Create OnePac api Link
    const onePac = new HttpLink({
      uri: process.env.REACT_APP_API_ENDPOINT,
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    });
    setClient(
      new ApolloClient({
        typeDefs,
        link: from([
          // Global error handler middleware
          onError(({ graphQLErrors }) => {
            setSnackbarContent({
              message: graphQLErrors?.[0]?.message ?? 'An Error as occurred',
              severity: 'error',
            });
            setIsSnackbarOpen(true);
            if (graphQLErrors)
              graphQLErrors.map(({ message, locations, path }) =>
                console.log(
                  `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
              );
          }),
          ApolloLink.split(
            (operation) => operation.getContext().clientName === 'onepac', // Routes the query to the proper client
            onePac
          ),
        ]),
        cache: new InMemoryCache(),
      })
    );
  }, [accessToken]);

  if (!client) return null;

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <GlobalStyles styles={{}} />
        <ApolloProvider client={client}>{content}</ApolloProvider>
      </ThemeProvider>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity={snackbarContent.severity as AlertColor}
          variant="filled"
        >
          {snackbarContent.message}
        </Alert>
      </Snackbar>
    </StyledEngineProvider>
  );
}

export default App;
