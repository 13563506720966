import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddOnePacUser from './modals/AddOnePacUser';
import ConfigureOnePacClient from './modals/ConfigureOnePacClient';
import {
  GetClientsOnePac,
  GetClientsOnePac_clients,
  GetClientsOnePac_getAllEntities,
} from 'schemaInterfaces/GetClientsOnePac';
import ConfigureClientOffers from './modals/ConfigureClientOffers';
import { OperationVariables, ApolloQueryResult } from '@apollo/client';

const ClientsListResults = ({
  clients,
  searchText,
  handleAddUser,
  entities,
  refetch,
  ...rest
}: {
  clients: GetClientsOnePac_clients[] | undefined;
  searchText: string;
  handleAddUser: (user: any, clientId: String) => void;
  entities: GetClientsOnePac_getAllEntities[];
  refetch: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<GetClientsOnePac>>;
}) => {
  const [open, setOpen] = useState('');
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);
  const [isConfigurationOpen, setIsConfigurationOpen] = useState(false);
  const [isConfigurationOffersOpen, setIsConfigurationOffersOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState<
    GetClientsOnePac_clients
  >();

  return (
    <Card {...rest}>
      <Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>ID</TableCell>
                <TableCell>Nom</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients?.map((client) => (
                <React.Fragment key={client.id}>
                  <TableRow
                    key={client.id}
                    sx={{ '& > *': { borderBottom: 'unset' } }}
                  >
                    <TableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          if (client.id === open) setOpen('');
                          else setOpen(client.id);
                        }}
                      >
                        {open === client.id ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        {client.id}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Typography color="textPrimary" variant="body1">
                          {client.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                        }}
                      >
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setSelectedClient(client);
                            setIsAddUserOpen(true);
                          }}
                          style={{ textTransform: 'none' }}
                        >
                          Ajouter un user pour ce client
                        </Button>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            setSelectedClient(client);
                            setIsConfigurationOpen(true);
                          }}
                          style={{ textTransform: 'none', marginLeft: 10 }}
                        >
                          Configurer le client
                        </Button>
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={() => {
                            setSelectedClient(client);
                            setIsConfigurationOffersOpen(true);
                          }}
                          style={{ textTransform: 'none', marginLeft: 10 }}
                        >
                          Configurer les offres
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        timeout="auto"
                        unmountOnExit
                        in={open === client.id}
                      >
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Assets
                          </Typography>
                          <Grid container>
                            {client?.config?.clientAssets?.logo && (
                              <Grid item container direction="row" xs={12}>
                                <p style={{ marginRight: 20 }}>Logo</p>
                                <img
                                  alt="logo"
                                  src={client.config.clientAssets?.logo}
                                  style={{ width: 100 }}
                                />
                              </Grid>
                            )}
                            {client?.config?.clientAssets?.banner && (
                              <Grid item container direction="row" xs={12}>
                                <p style={{ marginRight: 20 }}>Banner</p>
                                <img
                                  alt="banner"
                                  src={client.config.clientAssets?.banner}
                                />
                              </Grid>
                            )}
                            {client?.config?.clientAssets?.sms && (
                              <Grid item container direction="row" xs={12}>
                                <p style={{ marginRight: 20 }}>Sms</p>
                                <img
                                  alt="sms"
                                  src={client.config.clientAssets?.sms}
                                  style={{ width: 250 }}
                                />
                              </Grid>
                            )}
                            {client?.config?.clientAssets?.digital && (
                              <Grid item container direction="row" xs={12}>
                                <p style={{ marginRight: 20 }}>Digital</p>
                                <img
                                  alt="digital"
                                  src={client.config.clientAssets?.digital}
                                  style={{ width: 250 }}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <ConfigureOnePacClient
        open={isConfigurationOpen}
        setIsOpen={setIsConfigurationOpen}
        client={selectedClient}
      />
      <ConfigureClientOffers
        open={isConfigurationOffersOpen}
        setIsOpen={setIsConfigurationOffersOpen}
        client={selectedClient}
        refetch={refetch}
      />
      {isAddUserOpen && (
        <AddOnePacUser
          open={isAddUserOpen}
          setIsOpen={setIsAddUserOpen}
          client={selectedClient}
          entities={entities.filter(
            (entity) => entity.clientId === selectedClient?.id
          )}
          handleOnSave={handleAddUser}
        />
      )}
    </Card>
  );
};

ClientsListResults.propTypes = {
  clients: PropTypes.array.isRequired,
};

export default ClientsListResults;
