import React, { Component, createContext, useContext } from 'react';
import { Auth0Client, RedirectLoginOptions } from '@auth0/auth0-spa-js';
import history from './history';
import Splash from '../components/Splash/Splash';
import Loader from '../components/Loader/Loader';

type Auth0ContextValueType = {
  isAuthenticated: boolean;
  accessToken?: string;
  loginWithRedirect: (options: RedirectLoginOptions) => Promise<void>;
  logout: () => void;
};

interface AuthState {
  auth0Client: Auth0Client;
  isAuthenticated?: boolean;
  accessToken?: string;
  error?: string;
}

export const Auth0Context = createContext<Auth0ContextValueType | undefined>(
  undefined
);

export const useAuth0 = () => {
  const context = useContext(Auth0Context);
  if (!context)
    throw new Error('useAuth0 must be used inside a Provider with a value.');
  return context;
};

export class Auth0Provider extends Component<{}, AuthState> {
  constructor(props: {}) {
    super(props);
    // Init the Auth0Client SDK
    this.state = {
      auth0Client: new Auth0Client({
        domain: `${process.env.REACT_APP_AUTH0_APP_DOMAIN}`,
        client_id: `${process.env.REACT_APP_AUTH0_APP_CLIENT_ID}`,
        redirect_uri: `${process.env.REACT_APP_ROOT_URI}`,
        audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`,
        cacheLocation: 'localstorage',
        useRefreshTokens: true,
        advancedOptions: {
          defaultScope: 'profile email address phone',
        },
      }),
    };
  }

  async componentDidMount() {
    const auth0Client = this.state.auth0Client;

    try {
      // This handles the redirect callback from auth0
      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0Client.handleRedirectCallback();
        history.push(
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        );
      }

      const idToken = await auth0Client.getUser();
      // Check if the user is authenticated
      if (idToken) {
        const accessToken = await auth0Client.getTokenSilently();
        this.setState({
          isAuthenticated: true,
          accessToken,
        });
      } else {
        // Go to login page when the user is not authenticated
        await auth0Client.loginWithRedirect({
          appState: { targetUrl: window.location.pathname },
        });
      }
    } catch (error) {
      this.setState({
        isAuthenticated: false,
        error: (error as any).error_description || (error as any).message,
      });
    }
  }

  render() {
    const { auth0Client, isAuthenticated, accessToken, error } = this.state;

    if (error)
      return <Splash title="Authorization Error" body={error} homeButton />;

    if (!isAuthenticated) return <Loader height="100vh" />;

    const configObject = {
      isAuthenticated,
      accessToken,
      loginWithRedirect: (options: RedirectLoginOptions) =>
        auth0Client.loginWithRedirect(options),
      logout: () =>
        auth0Client.logout({ returnTo: process.env.REACT_APP_ROOT_URI }),
    };

    return (
      <Auth0Context.Provider value={configObject}>
        {this.props.children}
      </Auth0Context.Provider>
    );
  }
}
