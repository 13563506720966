import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Alert,
  Box,
  CircularProgress,
  Container,
  Snackbar,
} from '@mui/material';
import UsersListResults from '../../components/UsersOnePac/UsersListResults';
import UsersListToolbar from '../../components/UsersOnePac/UsersListToolbar';
import { gql, useMutation, useQuery } from '@apollo/client';

const GET_USERS = gql`
  query GetUsersOnePac {
    getPublicUserProfiles(filters: {}) {
      id
      email
      familyName
      givenName
      clientId
      isAdmin
      type
      role {
        id
        name
      }
      entities {
        id
        name
      }
    }
    getAllEntities {
      id
      name
    }
    clients {
      id
      name
    }
  }
`;

const UPDATE_USER_ONEPAC = gql`
  mutation UpdateUser(
    $id: String!
    $user: UpdateUserInput!
    $clientId: String!
  ) {
    updateUser(id: $id, user: $user, clientId: $clientId) {
      id
      clientId
      email
      givenName
      familyName
      type
      role {
        name
      }
      team {
        name
      }
    }
  }
`;

const UsersOnePac = () => {
  const [searchText, setSearchText] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const { data, error, loading, refetch } = useQuery(GET_USERS, {
    context: { clientName: 'onepac' },
  });
  const [updateUserOnePac, { data: dataMutation }] = useMutation(
    UPDATE_USER_ONEPAC,
    {
      context: { clientName: 'onepac' },
      onCompleted: () => {
        refetch();
      },
    }
  );

  function handleUpdateUser(user: any, id: String) {
    updateUserOnePac({
      variables: {
        user: {
          email: user.email,
          givenName: user.givenName,
          familyName: user.familyName,
          isAdmin: user.isAdmin,
          role: user.role,
          entities: user.entities,
        },
        id: id,
        clientId: user.clientId,
      },
    });
  }

  useEffect(() => {
    if (dataMutation) {
      setIsSnackbarOpen(true);
    }
  }, [dataMutation]);

  console.log('data', data);

  if (loading) return <CircularProgress />;
  if (error) return <div>There is an error</div>;
  return (
    <div>
      <Helmet>
        <title>OneManager - OP Users</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
          overflow: 'hidden',
        }}
      >
        <Container maxWidth={false}>
          <UsersListToolbar
            searchText={searchText}
            setSearchText={setSearchText}
            entities={data?.getAllEntities}
          />
          <Box sx={{ pt: 3 }}>
            <UsersListResults
              users={
                searchText?.length === 0
                  ? data?.getPublicUserProfiles
                  : data?.getPublicUserProfiles.filter((user: any) => {
                      return user.email
                        .toLowerCase()
                        .includes(searchText.toLowerCase());
                    })
              }
              searchText={searchText}
              handleUpdateUser={handleUpdateUser}
              entities={data?.getAllEntities}
              clients={data?.clients}
            />
          </Box>
        </Container>
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={() => setIsSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setIsSnackbarOpen(false)}
          severity="success"
          variant="filled"
        >
          Le user a bien été modifié !
        </Alert>
      </Snackbar>
    </div>
  );
};

export default UsersOnePac;
