import { gql } from '@apollo/client';

export const typeDefs = gql`
  extend type Metadata {
    fileExtension: String!
  }
  extend type Asset {
    displayedThumbUrl: String!
  }
`;
