import { useQuery, gql } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    user: getCurrentUser {
      id
      email
      givenName
      familyName
      isAdmin
    }
  }
`;

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%',
}));

const DashboardLayoutWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 256,
  },
}));

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden',
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
});

const DashboardLayout = (): JSX.Element => {
  let navigate = useNavigate();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const { data, error, loading } = useQuery(GET_CURRENT_USER, {
    context: { clientName: 'onepac' },
  });

  if (loading) return <CircularProgress />;
  if (error) return <div>There is an error</div>;

  console.log('data', data.user);

  if (!data?.user?.isAdmin) {
    navigate('/access-denied');
  }

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        currentUser={data?.user}
      />
      <DashboardLayoutWrapper>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </DashboardLayoutWrapper>
    </DashboardLayoutRoot>
  );
};

export default DashboardLayout;
